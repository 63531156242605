@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

html {
  height: -webkit-fill-available;
}

body {
  font-family: "Nunito", sans-serif;
  background: #0f2027;
  background: -webkit-linear-gradient(to right, #2c5364, #203a43, #0f2027);
  background: linear-gradient(to right, #2c5364, #203a43, #0f2027);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  height: 100%;
}

#root {
  min-height: 100%;
}

button {
  font-family: "Nunito", sans-serif;
}

.text-center{
  text-align: center;
}

.app {
  min-height: 100vh;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

h1 {
  font-weight: 700;
  color: #fff;
  margin: 1rem 0 1.5rem 0;
  text-align: center;
}

h3 {
  opacity: 0;
  font-weight: 700;
  color: #fff;
  line-height: 1.5rem;
  margin: 0 0 1.5rem 0;
  text-align: center;
  transition: all 0.2s ease-in;
}

h3.finished {
  opacity: 1;
  transition: all 0.5s ease-in;
}

ul.todo-list {
  width: 350px;
  max-width: 350px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transition: all 0.3s ease-in;
}

li.todo {
  margin: 0 1rem 0.5rem 1rem;
  position: relative;
  align-items: center;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  padding: 1rem;
  word-break: break-word;
  color: #666666;
}

li.todo.done {
  color: #fff;
  background: linear-gradient(to right, #ffffff 50%, #48bb78 50%) right;
  background-size: 200%;
  transition: color 0.5s ease-out, background 0.5s ease-out;
  text-decoration: line-through;
  background-repeat: no-repeat;
}

li.todo .checkbox {
  display: block;
  border: 2px solid #666;
  width: 12px;
  height: 12px;
  border-radius: 2.5px;
  position: absolute;
  cursor: pointer;
  top: 1rem;
  left: 0.75rem;
}

li.todo .check {
  display: none;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 1rem;
  left: 0.5rem;
  margin-left: 2px;
  margin-top: -2px;
  box-sizing: border-box;
}

li.todo .text {
  margin-left: 1rem;
  margin-right: 1rem;
  line-height: 1rem;
  cursor: pointer;
}

li.todo .remove {
  border: none !important;
  background-color: transparent !important;
  outline: none !important;
  padding: 0;
  display: none;
  position: absolute;
  right: 0.5rem;
  height: 20px;
  width: 20px;
  top: 0.75rem;
  margin-top: 2px;
  cursor: pointer;
}

li.todo:hover .remove {
  display: block;
}

li.todo .remove:hover {
  display: block;
}

li.todo.done .checkbox {
  display: none;
}

li.todo.done .check {
  display: block;
}

.todo-form {
  margin: 1rem 0 5rem 0;
  width: 350px;
  max-width: 350px;
}

.add-todo {
  background-color: #fff;
  margin: 0 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  padding: 0.75rem 1rem;
  position: relative;
  border: 2px solid transparent;
}

.add-todo input {
  border: none !important;
  outline: none !important;
  height: 1rem;
  color: #666666;
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  width: 100%;
}

.add-todo .add {
  display: block;
  position: absolute;
  right: 0.5rem;
  height: 20px;
  width: 20px;
  top: 0.75rem;
  cursor: pointer;
}

.modal-overlay {
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
}

.modal-overlay > div {
  border: none !important;
}

.modal-close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

form.error > div {
  border: solid 2px red;
}

.wiggle {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

footer {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  margin-top: 3rem;
}

footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

footer ul a {
  color: #fff;
  text-decoration: none;
  font-size: 0.85rem;
}

footer ul a:hover {
  text-decoration: underline;
}
